<video id="video_{{idx}}" 
    class="video-js vjs-default-skin" playsinline>
</video>

  <!-- Controls -->
  <div *ngIf="isRecordingFinished">
    <h3>Video đã quay xong</h3>
    
    <!-- Video Preview -->
    <video *ngIf="recordedVideoUrl" controls [src]="recordedVideoUrl"></video>

    <!-- Button to re-record or clear -->
    <button (click)="reRecord()">Quay lại</button>
    <button (click)="clearVideo()">Xóa video</button>
  </div>
