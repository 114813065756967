<mat-toolbar color="primary" class="mat-elevation-z6">
  <span class="app-name">
    <mat-icon aria-hidden="false" aria-label="Menu icon">menu</mat-icon>
    &nbsp;
  </span>
  <span class="appTitle">NgxVideoRecording</span>
  <a href="https://github.com/tejassavaliya/ngx-video-recording" target="_blank" mat-button class="example-icon">
    
    <mat-icon>download</mat-icon>&nbsp;&nbsp;GitHub
  </a>
</mat-toolbar>
<ng-container>
  <div class="videoContainer">
    <app-videojs-recording></app-videojs-recording>
  </div>
  
</ng-container>

